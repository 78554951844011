<template>
  <div>
    <client-only>
      <navbar :color="headerImage"
        @goto-content="scrollToContent($event)"
      >
      </navbar>
      <div id="home" class="text-md-center">
        <home :strapi="strapi"></home>
      </div>
      <about :strapi="strapi"></about>
      <div id="apps"><app :strapi="strapi"></app></div>
      <app2 :strapi="strapi"></app2>
      <app3></app3>
      <app4></app4>
    <!--<community :strapi="strapi"></community>-->
    <!--<teams :strapi="strapi" id="teams"></teams>-->
      <footer-component :strapi="strapi" id="contact"></footer-component>
    </client-only>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
    Navbar: () => import('@/components/Navbar'),
    Home: () => import('@/components/Home'),
    About: () => import('@/components/About'),
    App: () => import('@/components/App'),
    App2: () => import('@/components/App2'),
    App3: () => import('@/components/App3'),
    App4: () => import('@/components/App4'),
    //Community: () => import('@/components/Community'),
    //Teams: () => import('@/components/Teams'),
    FooterComponent: () => import('@/components/FooterComponent'),
  },
  data() {
    return {
      headerImage: true
    }
  },
  head: {
    title: process.env.company + ' | Workout Creator App on Google Play and App Store',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Are you looking for best workout and exercise creator app? Find the best workout creator app on Google Play and App Store. Any workout together anywhere anytime!'
      },
      {  
        hid: 'keywords',
        name: 'keywords',
        content: 'Exercise Creator, Workout creator app'
      }
    ],
  },
  mounted() {
        if(this.$route.params.hasOwnProperty("newid")){
           let scrollid = this.$route.params.newid;
           setTimeout(() => {
                this.scrollToContent(scrollid);
           }, 0, scrollid)
        }
  },
  async asyncData({ params, $http }) {
      const strapi = await $http.$get(`http://localhost:1338/strapisource`)
      return { strapi }; 
  },
  methods: {
    scrollToContent(id) {
      if (id) {
        const element = document.getElementById(id)
        const position = element.getBoundingClientRect()
        const win = element.ownerDocument.defaultView
        const x = position.left + win.pageXOffset
        const y = position.top + win.pageYOffset - 60
        window.scrollTo(x, y)
      }
    }
  }
}
</script>

<style scoped>
</style>
